.custom-button:hover {
	background-color: white !important;
	color: black !important;
	border-color: #344735 !important;
}

.custom-button:focus {
	background-color: #344735 !important;
	border-color: #344735 !important;
	color: white !important;
}

.spin-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.9); /* Optional: slight overlay */
}

.spin-message {
	margin-top: 16px;
	font-size: 16px;
	padding-left: 15px;
	color: #344735;
	text-align: center;
}
