.confirm-delete-model__button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-top: 3.2rem;
	&--btn {
		border: none;
		font-size: 1.8rem;
		padding: 1.3rem 3rem;
		border-radius: 500px;
		cursor: pointer;
		font-weight: 600;
	}
	&--save {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #000;
		font-size: 1.6rem;
		color: #fff;
		font-weight: 600;
		border-radius: 1000px !important;
		gap: 8px;
		padding: 1.3rem 2.8rem;
		border: none;
		cursor: pointer;
	}
	&--cancel {
		background: #fff;
		color: #000;
		border: 1px solid #000;
	}
}

.customCell {
	display: flex !important;
	justify-content: center !important;
}

.tab-content {
	max-height: 33vh;
	overflow-y: auto; /* Enable vertical scrolling within the content */
	padding: 10px; /* Optional: Add some padding inside each TabPane */
	box-sizing: border-box; /* Ensure padding is included in height calculation */
}

// .custom-form {
// 	max-height: 60vh;
// 	overflow-y: auto;
// }

.configuration-settings--logos {
	margin-top: 12px;
}

.qbo-modal {
	&__header {
		padding: 2rem !important;
		display: flex;
		gap: 1.1rem;
		align-items: center;
		width: 100%;
		justify-content: space-between;

		&-close {
			border: none !important;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.form-input {
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.buttons {
	display: flex;
	padding: 2rem;
}

.cancel {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.9rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	border: none;
	border-radius: 2.1rem;
	&:hover {
		cursor: pointer;
	}
}

.save {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4.2rem !important;
	width: 13rem !important;
	font-size: 1.7rem;
	color: white;
	border: 1px solid #344735;
	background-color: #344735;
	border-radius: 2.1rem;
	&:hover {
		cursor: pointer;
	}
}

.magic-wand {
	display: inline-block;
	padding-left: 10px;
	cursor: pointer;
	position: relative;
	font-size: 2rem; /* Adjust icon size */
}

.magic-wand::before,
.magic-wand::after,
.magic-wand .sprinkle-1,
.magic-wand .sprinkle-2,
.magic-wand .sprinkle-3 {
	content: '';
	font-size: x-small; /* Use a star symbol here */
	position: absolute;
	top: 50%;
	left: 50%;
	width: 5px;
	height: 5px;
	background-color: #000; /* Light gold/yellow color for the sprinkle */
	border-radius: 50%;
	animation: sprinkleAnimation 1s infinite ease-in-out;
	opacity: 0; /* Initially hide the sprinkle effect */
	animation-play-state: paused; /* Pause animation when not loading */
}

.magic-wand.loading::before,
.magic-wand.loading::after,
.magic-wand.loading .sprinkle-1,
.magic-wand.loading .sprinkle-2,
.magic-wand.loading .sprinkle-3 {
	opacity: 1; /* Show the sprinkle effect only when loading */
	animation-play-state: running; /* Play animation when loading */
}

.magic-wand::before {
	animation-delay: 0s;
	transform: translate(-50%, -50%) scale(0.8);
}

.magic-wand::after {
	animation-delay: 0.3s;
	transform: translate(-50%, -50%) scale(0.6);
}

.magic-wand .sprinkle-1 {
	animation-delay: 0.5s;
	transform: translate(-50%, -50%) scale(0.9);
}

.magic-wand .sprinkle-2 {
	animation-delay: 0.7s;
	transform: translate(-50%, -50%) scale(0.7);
}

.magic-wand .sprinkle-3 {
	animation-delay: 1s;
	transform: translate(-50%, -50%) scale(1);
}

@keyframes sprinkleAnimation {
	0% {
		transform: translate(-50%, -50%) scale(0.5) translateY(0) translateX(0);
		opacity: 1;
	}
	25% {
		transform: translate(-50%, -50%) scale(1) translateY(-10px) translateX(10px);
		opacity: 0.8;
	}
	50% {
		transform: translate(-50%, -50%) scale(0.7) translateY(-20px)
			translateX(-10px);
		opacity: 0.5;
	}
	75% {
		transform: translate(-50%, -50%) scale(1) translateY(10px) translateX(5px);
		opacity: 0.8;
	}
	100% {
		transform: translate(-50%, -50%) scale(0.5) translateY(0) translateX(0);
		opacity: 1;
	}
}

.magic-wand svg {
	transition: transform 0.3s ease-in-out;
}

.magic-wand:hover svg {
	transform: scale(1.1);
}
